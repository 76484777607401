<template>
  <b-modal
    id="topup-manual-modal"
    title="เติมมือ"
    hide-footer
  >
    <validation-observer
      ref="topupForm"
    >
      <form @submit.prevent="onSubmit">
        <b-dropdown
          id="transferBankAccount"
          class="w-100 mb-3"
          variant="outline-dark"
        >
          <template #button-content>
            เลือกบัญชีที่โอน
          </template>
          <template v-if="depositBankAccounts.length > 0">
            <b-dropdown-item
              v-for="bank in depositBankAccounts" 
              :key="bank.id"
              block
              @click="selectedBank = bank"
            >
              <b-media vertical-align="center">
                <template #aside>
                  <bank-icon :bank-code="bank.bankCode" />
                </template>
                {{ bank.bankAccountNumber }}<br>
                {{ bank.bankAccountName }}
              </b-media>
            </b-dropdown-item>
          </template>
          <b-dropdown-item
            v-else
            disabled
          >
            ไม่มีบัญชี
          </b-dropdown-item>
        </b-dropdown>
        <b-card
          v-if="selectedBank.bankCode != undefined"
          class="bg-soft-success"
        >
          <b-row align-v="center">
            <b-col>
              <b-media vertical-align="center">
                <template #aside>
                  <bank-icon :bank-code="selectedBank.bankCode" />
                </template>
                {{ selectedBank.bankAccountNumber }}<br>
                {{ selectedBank.bankAccountName }}
              </b-media>
            </b-col>
            <b-col cols="2">
              <feather
                type="check-circle"
                class="icon-lg align-middle icon-dual-success"
              />
            </b-col>
          </b-row>
        </b-card>
        <validation-provider
          v-slot="{ errors }"
          name="amount"
          rules="required"
        >
          <b-form-group
            label="จำนวนเงิน (บาท) *"
            label-for="amount"
          >
            <b-form-input
              id="amount"
              v-model="topupForm.amount"
              :state="errors[0] ? false : null"
            />
          </b-form-group>
        </validation-provider>
        <b-form-group
          label="เบอร์ยูสเซอร์ *"
          label-for="username"
        >
          <validation-provider
            v-slot="{ errors }"
            name="username"
            rules="required"
          >
            <b-form-input
              id="username"
              v-model="topupForm.username"
              :state="errors[0] ? false : null"
            />
          </validation-provider>
        </b-form-group>
        <p class="text-warning">
          กรอกวันเวลาตามสลิป
        </p>
        <b-row>
          <b-col>
            <b-form-group
              label="วัน *"
              label-for="date"
            >
              <validation-provider
                v-slot="{ errors }"
                name="date"
                rules="required"
              >
                <b-form-select
                  id="date"
                  v-model="date"
                  :options="dates"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="เดือน *"
              label-for="month"
            >
              <validation-provider
                v-slot="{ errors }"
                name="month"
                rules="required"
              >
                <b-form-select
                  id="month"
                  v-model="month"
                  :options="months"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="ปี *"
              label-for="year"
            >
              <validation-provider
                v-slot="{ errors }"
                name="year"
                rules="required"
              >
                <b-form-select
                  id="year"
                  v-model="year"
                  :options="years"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="ชั่วโมง *"
              label-for="hour"
            >
              <validation-provider
                v-slot="{ errors }"
                name="hour"
                rules="required"
              >
                <b-form-select
                  id="hour"
                  v-model="hour"
                  :options="hours"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="นาที *"
              label-for="mins"
            >
              <validation-provider
                v-slot="{ errors }"
                name="mins"
                rules="required|min:2"
              >
                <b-form-input
                  id="mins"
                  v-model="min"
                  placeholder="00 (ใส่สองตัว)"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          label="หมายเหตุ"
          label-for="remark"
        >
          <b-form-input
            id="remark"
            v-model="topupForm.remark"
          />
        </b-form-group>
        <div class="text-right">
          <b-button
            variant="light"
            class="mr-2"
            @click="$bvModal.hide('topup-manual-modal')"
          >
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-overlay
            :show="loading"
            rounded
            spinner-small
            class="d-inline-block"
          >
            <b-button
              type="submit"
              variant="primary"
              block
            >
              {{ $t('buttons.confirm') }}
            </b-button>
          </b-overlay>
        </div>
      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'
import { required } from '@validations'

export default {
  name: 'TopUpManualModal',
  props: {
    txn: {
      type: Object,
      default: ()=> {},
    }
  },
  data() {
    return {
      required,

      loading: false,
      selectedBank: {},
      topupForm: {
        amount: '',
        username: '',
        transactionDateTime: '',
        agentBankAccountId: '',
        remark: '',
      },
      date: '',
      month: '',
      year: '',
      hour: '',
      min: '',
    }
  },
  computed: {
    ...mapState({
      isFetchingBankAccounts: (state) => state.deposit.isFetchingBankAccounts,
    }),
    ...mapGetters(['userInfo', 'depositBankAccounts']),
    dates() {
      const date = new Array(31)
      date.fill(1)
      return date.map((val, key) => { return { value: `${key+val}`, text: `${key+val}`}})
    },
    months() {
      return [
        { value: '1', text: 'มกราคม'},
        { value: '2', text: 'กุมภาพันธ์'},
        { value: '3', text: 'มีนาคม'},
        { value: '4', text: 'เมษายน'},
        { value: '5', text: 'พฤษภาคม'},
        { value: '6', text: 'มิถุนายน'},
        { value: '7', text: 'กรกฎาคม'},
        { value: '8', text: 'สิงหาคม'},
        { value: '9', text: 'กันยายน'},
        { value: '10', text: 'ตุลาคม'},
        { value: '11', text: 'พฤศจิกายน'},
        { value: '12', text: 'ธันวาคม'},
      ]
    },
    years() {
      const currentYear = (dayjs().year() + 533);
      const year = new Array(30)
      year.fill(0)
      return year.map((val, key) => { return { value: `${currentYear+key}`, text: `${currentYear+key}`}})
    },
    hours() {
      const vals = new Array(24)
      vals.fill(1)
      return vals.map((val, key) => { return { value: `${key+val}`, text: `${key+val}`}})
    },
  },
  watch: {
    selectedBank(val){
      if(val){
        this.topupForm.agentBankAccountId = val.id
      }
    },
  },
  created() {
    this.fetchDepositBankAccounts();
  },
  methods: {
    ...mapActions(['fetchDepositBankAccounts', 'addDepositManual']),
    closeModal() {
      this.$bvModal.hide('topup-manual-modal')
      this.topupForm = {
        amount: '',
        username: '',
        transactionDateTime: '',
        agentBankAccountId: '',
        remark: '',
      }
      this.date = ''
      this.month = ''
      this.year = ''
      this.hour = ''
      this.min = ''
    },
    async onSubmit() {
      this.$refs.topupForm.validate().then( async (success) => {
        if (success) {
          this.topupForm.amount = +this.topupForm.amount
          const  transactionDateTime = dayjs().set('date', this.date)
          .set('month', (this.month - 1))
          .set('second', 0)
          .set('year', (this.year - 543))
          .set('hour', this.hour)
          .set('minute', this.min)
            this.topupForm.transactionDateTime = transactionDateTime
            await this.addDepositManual({
              agentId: this.userInfo.agentId, data: this.topupForm
            })
            this.closeModal()
        }
      })
      
    },
  },
}
</script>
